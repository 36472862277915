<template>
  <el-card class="box-card" shadow="always">
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button-group style="float: right;">
        <el-button v-hasPerm="['market:api:example']" size="mini" icon="el-icon-s-data" round
          @click="handleCall">{{ $t('cip.dc.dataapi.field.interfaceCall') }}</el-button>
        <el-button size="mini" icon="el-icon-back" round @click="showCard">{{ $t('cip.cmn.btn.goBackBtn') }}</el-button>
      </el-button-group>
    </div>
    <div class="body-wrapper">
      <el-form ref="form1" :model="form" label-width="100px" :disabled="true">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataapi.field.apiName')">
              <el-input v-model="form.apiName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataapi.field.apiVersion')">
              <el-input v-model="form.apiVersion" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataapi.field.reqMethod')">
              <el-input v-model="form.reqMethod" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataapi.field.resType')">
              <el-input v-model="form.resType" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item :label="$t('cip.dc.dataapi.field.apiUrl')">
              <el-input v-model="'/services/' + form.apiVersion + form.apiUrl" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="left">{{ $t('cip.dc.dataapi.field.reqData') }}</el-divider>
      <el-row>
        <el-col :span="24">
          <el-tabs v-model="activeTabName" type="border-card">
            <el-tab-pane :label="$t('cip.dc.dataapi.field.reqHead')" name="table0">
              <el-table :data="apiHeaderList" stripe border :max-height="300" style="width: 100%; margin: 15px 0;">
                <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="KEY" :label="$t('cip.dc.dataapi.field.KEY')" align="center"
                  show-overflow-tooltip />
                <el-table-column prop="VALUE" :label="$t('cip.dc.dataapi.field.VALUE')" align="center"
                  show-overflow-tooltip />
                <el-table-column prop="DESCRIPTION" :label="$t('cip.dc.dataapi.field.DESCRIPTION')" align="center"
                  show-overflow-tooltip />
              </el-table>
            </el-tab-pane>
            <el-tab-pane :label="$t('cip.dc.dataapi.field.reqParams')" name="table1">
              <el-table :data="form.reqParams" stripe border :max-height="300" style="width: 100%; margin: 15px 0;">
                <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="paramName" :label="$t('cip.dc.dataapi.field.paramName')" align="center"
                  show-overflow-tooltip />
                <el-table-column prop="nullable" :label="$t('cip.dc.dataapi.field.nullable')" align="center"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.nullable" true-label="1" false-label="0" disabled />
                  </template>
                </el-table-column>
                <el-table-column prop="paramComment" :label="$t('cip.dc.dataapi.field.paramComment')" align="center"
                  show-overflow-tooltip />
                <el-table-column prop="paramType" :label="$t('cip.dc.dataapi.field.paramType')" align="center"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.paramType"
                      :placeholder="$t('cip.cmn.rule.selectWarning') + $t('cip.dc.dataapi.field.paramType')" disabled>
                      <el-option v-for="dict in paramTypeOptions" :key="dict.id" :label="dict.itemValue"
                        :value="dict.itemText" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="whereType" :label="$t('cip.dc.dataapi.field.whereType')" align="center"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.whereType"
                      :placeholder="$t('cip.cmn.rule.selectWarning') + $t('cip.dc.dataapi.field.whereType')" disabled>
                      <el-option v-for="dict in whereTypeOptions" :key="dict.id" :label="dict.itemValue"
                        :value="dict.itemText" />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="paramValue" :label="$t('cip.dc.dataapi.field.paramValue')" align="center"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.paramValue" @change="changeparamValue($event,scope.row)"
                      :placeholder="$t('cip.cmn.rule.inputWarning') + $t('cip.dc.dataapi.field.paramValue')" />
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>


      </el-row>
      <br>
      <el-form ref="form3" :model="form3" label-width="100px" :disabled="true">
        <el-row>
          <el-form-item :label="$t('omit.reqUrl')">
            <el-input v-model="requrl" />
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item :label="$t('cip.dc.dataapi.field.reqParams')">
            <el-input type="textarea" autosize v-model="seereqParams" />
          </el-form-item>
        </el-row>

      </el-form>


      <el-divider content-position="left">{{ $t('cip.dc.dataapi.field.returnField') }}</el-divider>
      <el-row>
        <el-col :span="24">
          <div v-if="apiExecuting">
            <el-table :data="callData.dataList" stripe border :max-height="200" style="width: 100%; margin: 15px 0;">
              <el-table-column :label="$t('datacenter.dataQuality.index')" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <template v-for="(column, index) in callData.columnList">
                <el-table-column :key="index" :prop="column" :label="column" align="center" show-overflow-tooltip />
              </template>
            </el-table>
            <el-pagination :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="callData.pageNum" :page-size.sync="callData.pageSize" :total="callData.dataTotal"
              @size-change="handleSizeChange" @current-change="handleCurrentChange" />
          </div>
          <div v-else>{{ $t('cip.dc.dataapi.field.noData') }}</div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import { getDataApiDetail } from '@/api/market/dataapi'
import { getApiCall, postApiCall } from '@/api/market/apimapping'
import { getDicts } from '@/api/dict'
import ServerNameEnum from '@/util/ServerNameEnum';

export default {
  name: 'DataApiExample',
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      form3: {},
      requrl: "",
      seereqParams:{},
      title: '数据API调用',
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showAdd: false,
        showEdit: false,
        showDetail: false,
        showExample: false
      },
      activeTabName: 'table0',
      form: {},
      apiHeader: {},
      apiHeaderList: [],
      // 操作符数据字典
      whereTypeOptions: [],
      // 参数类型数据字典
      paramTypeOptions: [],
      apiExecuting: false,
      callData: {
        dataList: [],
        columnList: [],
        pageNum: 1,
        pageSize: 20,
        dataTotal: 0
      }
    }
  },
  created() {
    console.log('id:' + this.data.id)
    getDicts('data_where_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.whereTypeOptions = response.data
        
      }
    })
    getDicts('data_param_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.paramTypeOptions = response.data
        
       
      }
    })
  },
  mounted() {
    this.getDataApi(this.data.id)
  },
  methods: {
    showCard() {
      this.$emit('showCard', this.showOptions)
    },
    /** 获取详情 */
    getDataApi: function (id) {
      getDataApiDetail(id).then(res => {
        let response = res.data;
        if (response.success) {
          const { data } = response
          this.form = data.data
          this.apiHeader = data.header
          var domain = window.location.host;
          const url = 'services/' + this.form.apiVersion + this.form.apiUrl
        this.requrl ="http://" + domain+ ServerNameEnum.SERVER_ZT_+'/' + url,

          this.apiHeaderList.push({ 'KEY': 'api_key', 'VALUE': data.header.apiKey, 'DESCRIPTION': '' })
          this.apiHeaderList.push({ 'KEY': 'secret_key', 'VALUE': data.header.secretKey, 'DESCRIPTION': '' })
          this.$nextTick(()=>{
            this.changeparamValue(null,null,1)
        })
         
         
        }
      })
    },
    handleSizeChange(val) {
      this.callData.pageNum = 1
      this.callData.pageSize = val
      this.handleCall()
    },
    handleCurrentChange(val) {
      this.callData.pageNum = val
      this.handleCall()
    },
    changeparamValue(e,row,isp){
     
      const data = {}
      data.pageNum = this.callData.pageNum
      data.pageSize = this.callData.pageSize
      if(!isp){
        if(row.paramType=='2'||row.paramType=='3'){
        row.paramValue = parseInt(row.paramValue) 
      }
      }

        
     
      this.form.reqParams.forEach(param => {
        this.$set(data, param.paramName, param.paramValue)
      })

      
      
      // this.seereqParams =JSON.parse(JSON.stringify(data)) 
      this.seereqParams ={...data}
      for(let item in this.seereqParams){
       
        if(this.seereqParams[item]==null||this.seereqParams[item]==undefined){
          this.seereqParams[item]=""
        }
      }
      this.seereqParams =JSON.parse(JSON.stringify(this.seereqParams)) 
      this.seereqParams= JSON.stringify(this.seereqParams, null, '\t');
    },
    handleCall() {
      const url = 'services/' + this.form.apiVersion + this.form.apiUrl
      const header = { api_key: this.apiHeader.apiKey, secret_key: this.apiHeader.secretKey }
      const data = {}
      data.pageNum = this.callData.pageNum
      data.pageSize = this.callData.pageSize
      this.form.reqParams.forEach(param => {
        this.$set(data, param.paramName, param.paramValue)
      })
      if (this.form.reqMethod === 'GET') {
        getApiCall(url, header, data).then(res => {
          let response = res.data;
          if (response.success) {
            const { data } = response
            const dataList = data.data || []
            let columnList = []
            if (dataList.length > 0) {
              columnList = Object.keys(dataList[0])
            }
            this.callData.dataList = dataList
            this.callData.columnList = columnList
            this.callData.dataTotal = data.total
            this.apiExecuting = true
          }
        })
      } else if (this.form.reqMethod === 'POST') {
        postApiCall(url, header, data).then(res => {
          let response = res.data;
          if (response.success) {
            const { data } = response
            const dataList = data.data || []
            let columnList = []
            if (dataList.length > 0) {
              columnList = Object.keys(dataList[0])
            }
            this.callData.dataList = dataList
            this.callData.columnList = columnList
            this.callData.dataTotal = data.total
            this.apiExecuting = true
          }
        })
      }
    }
  }
}
</script>
