<template>
  <el-card class="box-card" shadow="always">
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button-group style="float: right;">
        <!-- <el-button v-hasPerm="['market:api:word']" size="mini" icon="el-icon-coin" round @click="handleWord">{{$t('cip.dc.dataapi.field.apiDocument')}}</el-button> -->
        <el-button v-hasPerm="['market:api:example']" size="mini" icon="el-icon-s-data" round @click="handleExample">{{$t('cip.dc.dataapi.field.apiExample')}}</el-button>
        <el-button size="mini" icon="el-icon-back" round @click="showCard">{{$t('cip.cmn.btn.goBackBtn')}}</el-button>
      </el-button-group>
    </div>
    <div class="body-wrapper">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="$t('cip.dc.dataapi.field.attrConfig')" />
        <el-step :title="$t('cip.dc.dataapi.field.executeConfig')" />
        <el-step :title="$t('cip.dc.dataapi.field.paramsConfig')" />
      </el-steps>
      <el-form v-if="active == 1" ref="form1" :model="form1" label-width="100px" disabled>
        <el-form-item :label="$t('cip.dc.dataapi.field.apiName')" prop="apiName">
          <el-input v-model="form1.apiName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.apiName')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.apiVersion')" prop="apiVersion">
          <el-input v-model="form1.apiVersion" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.apiVersion')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.apiUrl')" prop="apiUrl">
          <el-input v-model="form1.apiUrl" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.apiUrl')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.reqMethod')" prop="reqMethod">
          <el-select v-model="form1.reqMethod" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.reqMethod')">
            <el-option
              v-for="dict in reqMethodOptions"
              :key="dict.id"
              :label="dict.itemValue"
              :value="dict.itemText"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.resType')" prop="resType" >
          <el-select disabled v-model="form1.resType" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.resType')">
            <el-option
              v-for="dict in resTypeOptions"
              :key="dict.itemValue"
              :label="dict.itemValue"
              :value="dict.itemText"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.deny')" prop="deny" style="display: none;">
          <el-input v-model="form1.deny" type="textarea" :placeholder="$t('cip.dc.dataapi.field.denyPlaceholder')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.rateLimit')" prop="rateLimit" style="display: none;">
          <el-radio-group v-model="form1.rateLimit.enable">
            <el-radio
              v-for="dict in whetherOptions"
              :key="dict.id"
              :label="dict.itemText"
            >{{ dict.itemValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="display: none;" v-if="form1.rateLimit.enable === '1'" :label="$t('cip.dc.dataapi.field.limiting')">
          {{$t('cip.dc.dataapi.field.every')}}<el-input-number v-model="form1.rateLimit.seconds" controls-position="right" :min="1" />{{$t('cip.dc.dataapi.field.second')}}
          <el-input-number v-model="form1.rateLimit.times" controls-position="right" :min="1" />{{$t('cip.dc.dataapi.field.times')}}
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.status')" prop="status">
          <el-radio-group v-model="form1.status" disabled>
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.id"
              :label="dict.itemText"
            >{{ dict.itemValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.remark')" prop="remark">
          <el-input v-model="form1.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.field.remark')" />
        </el-form-item>
      </el-form>
      <el-form v-if="active == 2" ref="form2" :model="form2" label-width="80px" disabled>
        <el-form-item :label="$t('cip.dc.dataapi.field.configType')" prop="configType">
          <el-select v-model="form2.configType" :placeholder="$t('cip.cmn.rule.selectWarning') + $t('cip.dc.dataapi.field.configType')">
            <el-option
              v-for="dict in configTypeOptions"
              :key="dict.id"
              :label="dict.itemValue"
              :value="dict.itemText"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataapi.field.sourceName')" prop="sourceId">
          <el-select v-model="form2.sourceId" :placeholder="$t('cip.cmn.rule.selectWarning') + $t('cip.dc.dataapi.field.sourceName')">
            <el-option
              v-for="source in sourceOptions"
              :key="source.id"
              :label="source.sourceName"
              :value="source.id"
              :disabled="source.status === '0'"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form2.configType === '1'" :label="$t('datacenter.datacolumn.tableName')" prop="tableName">
          <el-select v-model="form2.table" value-key="id" :placeholder="$t('cip.cmn.rule.selectWarning') + $t('datacenter.datacolumn.tableName') ">
            <el-option
              v-for="item in tableOptions"
              :key="item.id"
              :label="item.tableComment ? item.tableComment : item.tableName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form2.configType === '1'" :label="$t('cip.dc.dataapi.field.fieldList')">
          <el-table
            :data="form2.fieldParams"
            stripe
            border
            :max-height="300"
            style="width: 100%; margin: 15px 0;"
          >
          <el-table-column prop="columnPosition" :label="$t('datacenter.dataQuality.index')" width="55" align="center" />
            <el-table-column prop="columnName" :label="$t('cip.dc.dataapi.field.columnName')" align="center" show-overflow-tooltip />
            <el-table-column prop="dataType" :label="$t('datacenter.datacolumn.dataType')" align="center" show-overflow-tooltip />
            <el-table-column prop="dataLength" :label="$t('datacenter.datacolumn.dataLength')" align="center" show-overflow-tooltip />
            <el-table-column prop="dataPrecision" :label="$t('datacenter.datacolumn.dataPrecision')" align="center" show-overflow-tooltip />
            <el-table-column prop="dataScale" :label="$t('datacenter.datacolumn.dataScale')" align="center" show-overflow-tooltip />
            <el-table-column prop="columnKey" :label="$t('datacenter.datacolumn.isPrimaryKey')" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.columnKey === '1'">Y</span>
                <span v-if="scope.row.columnKey === '0'">N</span>
              </template>
            </el-table-column>
            <el-table-column prop="columnNullable" :label="$t('datacenter.datacolumn.isEmpty')" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.columnNullable === '1'">Y</span>
                <span v-if="scope.row.columnNullable === '0'">N</span>
              </template>
            </el-table-column>
            <el-table-column prop="dataDefault" :label="$t('cip.dc.dataapi.field.columnDataDefault')" align="center" show-overflow-tooltip />
            <el-table-column prop="columnComment" :label="$t('cip.dc.dataapi.field.columnComment')" align="center" show-overflow-tooltip />
            <el-table-column prop="reqable" :label="$t('cip.dc.dataapi.field.reqable')" align="center" width="50">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.reqable" true-label="1" false-label="0" />
              </template>
            </el-table-column>
            <el-table-column prop="resable" :label="$t('cip.dc.dataapi.field.resable')" align="center" width="50">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.resable" true-label="1" false-label="0" />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row v-if="form2.configType === '2'">
          <el-col :span="24">
            <sql-editor
              ref="sqleditor"
              :value="form2.sqlText"
              :read-only="true"
              style="height: 300px;margin: 10px 10px;"
            />
          </el-col>
        </el-row>
        <el-row v-if="form2.configType === '2'">
          <el-col :span="24">
            <el-form-item label="查询条件" >
              <el-input v-model="form1.whereParam"></el-input>
        </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-if="active == 3" ref="form3" :model="form3" label-width="80px" disabled>
        <el-divider content-position="left">{{$t('cip.dc.dataapi.field.reqParams')}}</el-divider>
        <el-table
          :data="form3.reqParams"
          stripe
          border
          :max-height="300"
          style="width: 100%; margin: 15px 0;"
        >
          <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index +1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="paramName" :label="$t('cip.dc.dataapi.field.paramName')" align="center" show-overflow-tooltip />
          <el-table-column prop="nullable" :label="$t('cip.dc.dataapi.field.nullable')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.nullable" true-label="1" false-label="0" />
            </template>
          </el-table-column>
          <el-table-column prop="paramComment" :label="$t('cip.dc.dataapi.field.paramComment')" align="center" show-overflow-tooltip />
          <el-table-column prop="paramType" :label="$t('cip.dc.dataapi.field.paramType')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-select v-model="scope.row.paramType" :placeholder="$t('cip.cmn.rule.selectWarning')+$t('cip.dc.dataapi.field.paramType')">
                <el-option
                  v-for="dict in paramTypeOptions"
                  :key="dict.id"
                  :label="dict.itemValue"
                  :value="dict.itemText"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="whereType" :label="$t('cip.dc.dataapi.field.whereType')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-select v-model="scope.row.whereType" :placeholder="$t('cip.cmn.rule.selectWarning')+$t('cip.dc.dataapi.field.whereType')">
                <el-option
                  v-for="dict in whereTypeOptions"
                  :key="dict.id"
                  :label="dict.itemValue"
                  :value="dict.itemText"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="exampleValue" :label="$t('cip.dc.dataapi.field.exampleValue')" align="center" show-overflow-tooltip />
          <el-table-column prop="defaultValue" :label="$t('cip.dc.dataapi.field.defaultValue')" align="center" show-overflow-tooltip />
        </el-table>
        <el-divider content-position="left">{{ $t('cip.dc.dataapi.field.returnField') }}</el-divider>
        <el-table
          :data="form3.resParams"
          stripe
          border
          :max-height="300"
          style="width: 100%; margin: 15px 0;"
        >
          <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index +1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="fieldName" :label="$t('datacenter.datacolumn.fieldName')" align="center" show-overflow-tooltip />
          <el-table-column prop="fieldComment" :label="$t('cip.dc.dataapi.field.fieldComment')" align="center" show-overflow-tooltip />
          <el-table-column prop="dataType" :label="$t('datacenter.datacolumn.dataType')" align="center" show-overflow-tooltip />
          <el-table-column prop="exampleValue" :label="$t('cip.dc.dataapi.field.exampleValue')" align="center" show-overflow-tooltip />
        </el-table>
      </el-form>
      <el-button v-if="active < 3" style="margin-top: 12px;" @click="handleNextStep">{{$t('datacenter.btn.next')}}</el-button>
      <el-button v-if="active > 1" style="margin-top: 12px;" @click="handleLastStep">{{$t('datacenter.btn.prev')}}</el-button>
    </div>
  </el-card>
</template>

<script>
import { getDataApi, word } from '@/api/market/dataapi'
import { listDataSource } from '@/api/metadata/datasource'
import { listDataTable } from '@/api/metadata/datatable'
import SqlEditor from '@/components/SqlEditor'
import { getDicts } from '@/api/dict'

export default {
  name: 'DataApiDetail',
  components: {
    SqlEditor
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      title: this.$t('cip.dc.dataapi.field.apiDetail'),
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showAdd: false,
        showEdit: false,
        showDetail: false,
        showExample: false
      },
      active: 1,
      // 表单参数
      form1: {
        id: undefined,
        apiName: undefined,
        apiVersion: undefined,
        apiUrl: undefined,
        reqMethod: undefined,
        resType: "JSON",
        deny: undefined,
        rateLimit: {
          enable: '1',
          times: 5,
          seconds: 60
        },
        times: 5,
        seconds: 60,
        status: '1',
        remark: undefined,
        executeConfig: {},
        reqParams: [],
        resParams: [],
        whereParam:"",
      },
      // 表单校验
      rules1: {
        apiName: [
          { required: true, message: this.$t('cip.dc.dataapi.field.apiName')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        apiVersion: [
          { required: true, message: this.$t('cip.dc.dataapi.field.apiVersion')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        apiUrl: [
          { required: true, message: this.$t('cip.dc.dataapi.field.apiUrl')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        reqMethod: [
          { required: true, message: this.$t('cip.dc.dataapi.field.reqMethod')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        resType: [
          { required: true, message: this.$t('cip.dc.dataapi.field.resType')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ]
      },
      form2: {
        configType: undefined,
        sourceId: undefined,
        tableId: undefined,
        tableName: undefined,
        fieldParams: [],
        sqlText: undefined,
      },
      rules2: {
        configType: [
          { required: true, message: this.$t('cip.dc.dataapi.field.configType')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        sourceId: [
          { required: true, message: this.$t('cip.dc.dataapi.field.sourceName')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ]
      },
      form3: {
        reqParams: [],
        resParams: []
      },
      // 请求方式数据字典
      reqMethodOptions: [],
      // 返回格式数据字典
      resTypeOptions: [
        {
          itemValue:'JSON',
          itemText:'JSON',
        }
      ],
      // 是否数据字典
      whetherOptions: [],
      // 状态数据字典
      statusOptions: [],
      // 数据源数据字典
      sourceOptions: [],
      // 数据库表数据字典
      tableOptions: [],
      // 配置方式数据字典
      configTypeOptions: [],
      // 操作符数据字典
      whereTypeOptions: [],
      // 参数类型数据字典
      paramTypeOptions: []
    }
  },
  created() {
    console.log('id:' + this.data.id)
    getDicts('data_req_method').then(res => {
      let response = res.data;
      if (response.success) {
        this.reqMethodOptions = response.data
      }
    })
    getDicts('data_res_type').then(res => {
      let response = res.data;
      if (response.success) {
        // this.resTypeOptions = response.data
      }
    })
    getDicts('sys_yes_no').then(res => {
      let response = res.data;
      if (response.success) {
        this.whetherOptions = response.data
      }
    })
    getDicts('data_api_status').then(res => {
      let response = res.data;
      if (response.success) {
        this.statusOptions = response.data
      }
    })
    this.getDataSourceList()
    getDicts('data_config_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.configTypeOptions = response.data
      }
    })
    getDicts('data_where_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.whereTypeOptions = response.data
      }
    })
    getDicts('data_param_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.paramTypeOptions = response.data
      }
    })
  },
  mounted() {
    this.getDataApi(this.data.id)
  },
  methods: {
    showCard() {
      this.$emit('showCard', this.showOptions)
    },
    getDataSourceList() {
      listDataSource().then(res => {
        let response = res.data;
        if (response.success) {
          this.sourceOptions = response.data
        }
      })
    },
    /** 步骤条下一步 */
    handleNextStep() {
      this.active++
    },
    /** 步骤条上一步 */
    handleLastStep() {
      this.active--
    },
    /** 获取详情 */
    async getDataApi(id) {
      this.form1 = await getDataApi(id).then(res => {
        let response = res.data;
        if (response.success) {
          return response.data
        }
      }) || {}
      this.form2 = this.form1.executeConfig
      this.form2.table = { id: this.form2.tableId }
      this.form3.reqParams = this.form1.reqParams
      this.form3.resParams = this.form1.resParams
      if (this.form2.configType === '1') {
        this.tableOptions = await listDataTable({ sourceId: this.form2.sourceId }).then(res => {
          let response = res.data;
          if (response.success) {
            return response.data
          }
        }) || []
      }
    },
    handleExample() {
      this.showOptions.data.id = this.data.id
      this.showOptions.showList = false
      this.showOptions.showAdd = false
      this.showOptions.showEdit = false
      this.showOptions.showDetail = false
      this.showOptions.showExample = true
      this.$emit('showCard', this.showOptions)
    },
    /** 接口文档 */
    handleWord() {
      word(this.data.id).then(res => {
        let response = res.data;
        const blob = new Blob([response])
        const fileName = this.$t('cip.dc.dataapi.field.fileName')
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
